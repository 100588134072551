import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import React from "react";
var __jsx = React.createElement;
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import Localize from '~/common/Localize';
import { Body3, Container, Theme, Title2, themed } from '~/styles/Theme';
var ServiceCategoryHeader = function ServiceCategoryHeader(props) {
  return __jsx(HeaderContainer, {
    testID: "serviceCategory",
    isActive: props.isActive
  }, __jsx(Title2, {
    style: {
      flex: 1
    }
  }, props.title), __jsx(View, {
    style: {
      flexDirection: 'row',
      paddingRight: 20,
      paddingTop: 5,
      paddingLeft: 5,
      alignItems: 'center'
    }
  }, props.numOfSelectedServices > 0 && __jsx(Selection, null, __jsx(SelectionText, null, "".concat(props.numOfSelectedServices, " ").concat(Localize('services.selected')))), __jsx(ServiceCounter, {
    testID: "serviceCounter"
  }, __jsx(CounterText, null, props.numOfServices)), __jsx(Icon, {
    name: props.isActive ? 'chevron-up' : 'chevron-down',
    color: "#515151",
    size: 25,
    testID: props.isActive ? 'expandedCategoryIcon' : 'collapsedCategoryIcon'
  })));
};
var HeaderContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-direction: row;\n  justify-content: space-between\n  padding-left: 20px;\n  padding-top: 13px;\n  padding-bottom: 13px;\n  ", ";\n  border-bottom-color: #C7CCD7;\n  ", ";\n  ", ";\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
}, function (props) {
  return "background-color: ".concat(props.isActive ? 'transparent' : 'white');
}, function (props) {
  return "border-bottom-width: ".concat(props.isActive ? '0px' : '1px');
});
var ServiceCounter = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 24px\n  height: 24px\n  flex-direction: row;\n  border-radius: 20px\n  justify-content: center\n  align-items: center\n  ", ";\n  border-width: 1px\n  margin-right: 10px\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral7);
});
var Selection = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 4px 10px\n  flex-direction: row;\n  border-radius: 20px\n  justify-content: center\n  align-items: center\n  ", ";\n  margin-right: 10px\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.success);
});
var CounterText = themed(Body3)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});
var SelectionText = themed(Body3)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral);
});
export default ServiceCategoryHeader;