import React from "react";
var __jsx = React.createElement;
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { clientCourses as clientCoursesResource } from '~/api/Resources';
import { TEN_MINS_IN_MILLISECONDS } from '~/api/models/Constants';
import { fetchResource } from '~/common/network/network';
import { selectBranchId } from '~/common/slices/app.slice';
import { selectIsUserLoggedIn, selectToken } from '~/common/slices/user.slice';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { buildActiveCourses } from '~/screens/account/clientCourses/ClientCourseViewModel';
import { ClientCourseItem } from '~/screens/account/components/UserAccountComponents';
var ClientCourseBanner = function ClientCourseBanner(_ref) {
  var preferredStaffId = _ref.preferredStaffId;
  var branchId = useSelector(selectBranchId);
  var token = useSelector(selectToken);
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var _useQuery = useQuery([clientCoursesResource.path, branchId, token], function () {
      return fetchResource(clientCoursesResource);
    }, {
      enabled: isUserLoggedIn,
      cacheTime: TEN_MINS_IN_MILLISECONDS
    }),
    clientCourses = _useQuery.data;
  var hasActiveCourses = useMemo(function () {
    return clientCourses ? buildActiveCourses(clientCourses.clientCourses).length > 0 : false;
  }, [clientCourses]);
  return hasActiveCourses ? __jsx(View, {
    style: {
      padding: 10,
      paddingTop: 0
    },
    testID: "clientCourseBanner"
  }, __jsx(ClientCourseItem, {
    courses: clientCourses,
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.ClientCourses));
    }
  })) : null;
};
export default ClientCourseBanner;