import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
import { View, TextInput, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import { Container, themed } from '~/styles/Theme';
var SearchInput = function SearchInput(props) {
  var placeholder = props.placeholder,
    testId = props.testId,
    setFilter = props.setFilter,
    filter = props.filter;
  var _useState = useState(false),
    isFocus = _useState[0],
    setIsFocus = _useState[1];
  var inputRef = useRef(null);
  var analytics = useAnalytics();
  function onFocus() {
    var _inputRef$current;
    (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 || _inputRef$current.focus();
    setIsFocus(true);
    analytics.logAnalyticsEvent(AnalyticsEvents.ClickSearch);
  }
  function onBlur() {
    var _inputRef$current2;
    (_inputRef$current2 = inputRef.current) === null || _inputRef$current2 === void 0 || _inputRef$current2.blur();
    setIsFocus(false);
  }
  function onClear() {
    var _inputRef$current3;
    (_inputRef$current3 = inputRef.current) === null || _inputRef$current3 === void 0 || _inputRef$current3.clear();
    setFilter('');
    analytics.logAnalyticsEvent(AnalyticsEvents.ClearSearch);
  }
  var onChangeText = function onChangeText(text) {
    debounce(function () {
      setFilter(text);
    }, 200)();
  };
  return __jsx(SearchBarInputContainer, {
    testID: testId
  }, __jsx(SearchBarMainInput, {
    style: {
      borderColor: isFocus ? '#282F3C' : '#D1D6DF'
    }
  }, __jsx(View, {
    style: {
      paddingRight: 10,
      paddingLeft: 10
    }
  }, __jsx(Icon, {
    name: "search",
    size: 30,
    style: {
      color: '#9CA3AF'
    }
  })), __jsx(TextInput, {
    style: [{
      fontSize: 16,
      color: '#282F3C',
      fontFamily: 'Montserrat',
      fontWeight: '500',
      marginTop: 0,
      marginBottom: Platform.OS === 'ios' ? 0 : 0,
      height: 48,
      shadowColor: 'transparent',
      flex: 1
    }],
    placeholder: placeholder,
    placeholderTextColor: "#D1D6DF",
    onFocus: onFocus,
    onBlur: onBlur,
    onChangeText: onChangeText,
    ref: inputRef,
    testID: "searchBarInput"
  }),
  // @ts-ignore
  inputRef.current && filter !== '' ? __jsx(Icon, {
    name: "x",
    size: 30,
    onPress: onClear,
    style: {
      color: '#9CA3AF',
      marginLeft: 'auto',
      alignItems: 'flex-end'
    }
  }) : null));
};
export default SearchInput;
var SearchBarInputContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  z-index: 10;\n  border-radius 4px;\n"])));
var SearchBarMainInput = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n  background-color: #FFFFFF;\n  margin: 10px 18px;\n  border: 1px solid #000;\n  height: 64px;\n  border-radius: 4px;\n  padding: 6px 12px;\n"])));