import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import Localize from '~/common/Localize';
import { selectBusiness } from '~/common/slices/model.slice';
import ListItem from '~/components/ListItem';
import OpenLink from '~/components/OpenLink';
import { Action1, Body2, Container, SubTitle1, SubTitle2, Theme, themed } from '~/styles/Theme';

// #################
// HEADER COMPONENTS
// #################

export var AccountHeader = function AccountHeader(props) {
  var user = props.user,
    children = props.children;
  return __jsx(HeaderView, null, user ? __jsx(ProfileName, null, user.firstName, " ", user.lastName) : __jsx(React.Fragment, null, __jsx(ProfileName, null, "..."), __jsx(ActivityIndicator, {
    color: "white"
  })), children);
};
export var UserPoints = function UserPoints(props) {
  var user = props.user;
  if (user && user.loyaltyAccount && user.loyaltyAccount.points) {
    return __jsx(Points, {
      points: user.loyaltyAccount.points
    });
  }
  return null;
};
var Points = function Points(props) {
  var _useState = useState(0),
    points = _useState[0],
    setPoints = _useState[1];
  useEffect(function () {
    if (points === props.points) {
      return;
    }
    setTimeout(function () {
      return setPoints(function (p) {
        var increment = Math.abs(p - props.points) > 10 ? 10 : 1;
        increment = p > props.points ? -increment : increment;
        return p + increment;
      });
    });
  });
  return __jsx(SubTitle, {
    testID: "userPoints"
  }, points, " ", Localize('account.treatcard'));
};

// #################
// ITEM COMPONENTS
// #################

export var PaymentItem = function PaymentItem(props) {
  return __jsx(ListItem, {
    title: Localize('account.payment'),
    image: __jsx(Icon, {
      name: "credit-card",
      size: 25
    }),
    subtitle: Localize('account.paymentSubtitle'),
    onPress: props.onPress,
    showChevron: true,
    border: true
  });
};
export var FeedbackItem = function FeedbackItem(props) {
  return __jsx(ListItem, {
    title: Localize('feedback.title'),
    image: __jsx(Icon, {
      name: "message-circle",
      size: 25
    }),
    subtitle: Localize('account.feedbackSubtitle'),
    onPress: props.onPress,
    showChevron: true,
    border: true,
    testID: "feedbackItem"
  });
};
export var ClientCourseItem = function ClientCourseItem(props) {
  var hasCoursesToRender = props.courses && props.courses.clientCourses.length > 0;
  return hasCoursesToRender ? __jsx(ListItem, {
    title: Localize('courses.title'),
    image: __jsx(Icon, {
      name: "star",
      size: 25
    }),
    subtitle: Localize('courses.subtitle'),
    onPress: props.onPress,
    showChevron: true,
    border: true,
    testID: "clientCourseItem"
  }) : null;
};
export var ProfileItem = function ProfileItem(props) {
  return __jsx(ListItem, {
    title: Localize('account.accountSettings'),
    image: __jsx(Icon, {
      name: "settings",
      size: 25
    }),
    subtitle: Localize('account.settingsSubText'),
    onPress: props.onPress,
    showChevron: true,
    border: true,
    testID: props.testID
  });
};
export var ReferralsItem = function ReferralsItem(props) {
  if (!props.enabled) {
    return null;
  }
  var badge = props.available ? __jsx(Badge, {
    testID: "referralsItemEnabled"
  }, __jsx(BadgeText, null, Localize('global.new'))) : __jsx(UnavilableBadge, {
    testID: "referralsItemDisabled"
  }, __jsx(Icon, {
    name: "info",
    size: 16
  }), __jsx(UnavilableBadgeText, null, Localize('global.unavailable')));
  return __jsx(ListItem, {
    title: Localize('loyalty.referrals.accountMenuTitle'),
    image: __jsx(Icon, {
      name: "zap",
      size: 25
    }),
    badge: badge,
    subtitle: Localize('loyalty.referrals.accountMenuSubtitle'),
    onPress: props.onPress,
    showChevron: props.enabled,
    border: true,
    testID: props.testID
  });
};
export var BranchSelectorItem = function BranchSelectorItem(props) {
  var branchName = props.branchName,
    onPress = props.onPress;
  var business = useSelector(selectBusiness);
  var hasMoreThanOneBranch = business !== undefined && business.branches !== undefined && business.branches.length > 1;
  return hasMoreThanOneBranch ? __jsx(ListItem, {
    title: Localize('account.branch'),
    image: __jsx(Icon, {
      name: "map-pin",
      size: 25
    }),
    subtitle: branchName ? branchName : '',
    onPress: onPress,
    showChevron: true,
    border: true,
    testID: "branchSelectorItem"
  }) : null;
};
export var TreatCardItem = function TreatCardItem(props) {
  var _user$loyaltyCard;
  var user = props.user,
    onPress = props.onPress,
    testID = props.testID;
  var badge = __jsx(Badge, {
    testID: "treatCardItemBadge"
  }, __jsx(BadgeText, null, Localize('global.new')));
  if (user !== null && user !== void 0 && (_user$loyaltyCard = user.loyaltyCard) !== null && _user$loyaltyCard !== void 0 && _user$loyaltyCard.serial) {
    return __jsx(ListItem, {
      title: Localize('account.treatcardTitle'),
      image: __jsx(Icon, {
        name: "gift",
        size: 25
      }),
      subtitle: Localize('account.treatcardSubtitle'),
      onPress: onPress,
      showChevron: true,
      border: true,
      badge: badge,
      testID: testID
    });
  }
  return null;
};
export var FAQItem = function FAQItem(props) {
  return __jsx(ListItem, {
    title: Localize('faq.title'),
    image: __jsx(Icon, {
      name: "help-circle",
      size: 25
    }),
    subtitle: Localize('faq.subtitle'),
    onPress: props.onPress,
    showChevron: true,
    border: true,
    testID: "FAQItem"
  });
};
export var LogOutItem = function LogOutItem(props) {
  return __jsx(ListItem, {
    testID: "logOutButton",
    title: Localize('profile.logout'),
    image: __jsx(Icon, {
      name: "log-out",
      size: 25
    }),
    onPress: props.onPress,
    border: true
  });
};
export var LegalItem = function LegalItem(props) {
  return __jsx(OpenLink, {
    url: props.url,
    style: {
      height: 55,
      justifyContent: 'center'
    },
    testID: props.testID
  }, __jsx(LegalItemTitle, null, props.title));
};
var LegalItemTitle = themed(SubTitle2)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-left: 20px;\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});
var SubTitle = themed(Body2)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral);
});
var ProfileName = themed(SubTitle1)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral);
});
var HeaderView = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  justify-content: center;\n  align-items: center;\n  padding: 15px 0;\n  ", ";\n "])), function (props) {
  return "background-color: ".concat(Theme(props).colors.navBarColor);
});
var Badge = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  align-items: center;\n  padding: 2px 16px;\n  border-radius: 12px;\n  margin-right: 16px;\n  ", ";\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.main);
});
var BadgeText = themed(Action1)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral);
});
var UnavilableBadge = themed(Container)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  flex-direction: row;\n  align-items: center;\n  padding: 2px 16px;\n  border-radius: 12px;\n"])), function (props) {
  return Theme(props).colors.neutral2;
});
var UnavilableBadgeText = themed(Action1)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin-left: 8px;\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral8);
});