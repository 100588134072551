import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';
import Localize from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import EmptySearch from '~/components/EmptySearch';
import EmptySearchResults from '~/components/Search/EmptySearchResults';
import SearchInput from '~/components/Search/SearchInput';
import { Container, themed } from '~/styles/Theme';
import { emptySearchText, hasBookableServices } from '../ServicesViewModel';
import ClientCourseBanner from './ClientCourseBanner';
import ServiceCategoryHeader from './ServiceCategoryHeader';
import ServiceItem from './ServiceItem';
export default ServicesList;
function ServicesList(props) {
  var viewModel = props.viewModel,
    preferredStaff = props.preferredStaff,
    onServicePress = props.onServicePress,
    onDescriptionPress = props.onDescriptionPress,
    filter = props.filter,
    setFilter = props.setFilter,
    searchEnabled = props.searchEnabled;
  var _useState = useState(),
    activeSections = _useState[0],
    setActiveSections = _useState[1];
  var _useState2 = useState(false),
    showEmptySearchResults = _useState2[0],
    setShowEmptySearchResults = _useState2[1];
  var analytics = useAnalytics();
  useEffect(function () {
    if (filter && filter.length > 1) {
      if (hasBookableServices(viewModel)) {
        setShowEmptySearchResults(false);
        var activeSectionsIdx = viewModel.categories.map(function (_, index) {
          return index;
        });
        setActiveSections(activeSectionsIdx);
      } else {
        setShowEmptySearchResults(true);
      }
    } else {
      setActiveSections(viewModel.defaultOpenIdx !== undefined ? [viewModel.defaultOpenIdx] : undefined);
    }
  }, [filter]);
  function renderCategoryHeader(category, _, isActive) {
    var _category$name;
    var categoryName = (_category$name = category.name) !== null && _category$name !== void 0 ? _category$name : '';
    return __jsx(ServiceCategoryHeader, {
      title: categoryName,
      numOfServices: "".concat(category.services.length),
      isActive: isActive,
      numOfSelectedServices: category.numberOfSelectedServices
    });
  }
  function _onServicePress(selected, serviceViewModel) {
    // log analytics event when a service is selected while searching
    if (filter && filter.length > 1) {
      analytics.logAnalyticsEvent(AnalyticsEvents.ServiceSearchResultClick);
    }
    onServicePress(selected, serviceViewModel, preferredStaff);
  }
  function _onDescriptionPress(service) {
    if (onDescriptionPress) {
      onDescriptionPress(service);
    }
  }
  function renderCategoryContent(category, _, isActive) {
    if (!isActive) {
      return __jsx(View, null);
    }
    return __jsx(ServiceContainer, {
      key: "category_".concat(category.id)
    }, category.services.map(function (service, idx) {
      return __jsx(ServiceItem, {
        key: "service_".concat(service.id, "_").concat(idx),
        service: service,
        onPress: _onServicePress,
        testID: "service_".concat(service.id),
        onDescriptionPress: onDescriptionPress ? function () {
          return _onDescriptionPress(service);
        } : undefined
      });
    }));
  }
  function onActiveSectionChange(activeCategoriesIdx) {
    activeCategoriesIdx.length === 0 ? setActiveSections(undefined) : setActiveSections([activeCategoriesIdx[0]]);
  }
  return __jsx(View, {
    style: {
      flex: 1
    }
  }, searchEnabled && __jsx(SearchInput, {
    testId: "servicesSearchInput",
    placeholder: Localize('service.search'),
    setFilter: setFilter,
    filter: filter
  }), hasBookableServices(viewModel) ? __jsx(ScrollView, {
    style: {
      flex: 1
    },
    testID: "serviceContainer"
  }, props.hideCourseBanner ? null : __jsx(ClientCourseBanner, {
    preferredStaffId: preferredStaff === null || preferredStaff === void 0 ? void 0 : preferredStaff.id
  }), __jsx(Accordion, {
    activeSections: activeSections !== undefined ? activeSections : [],
    sections: viewModel.categories.filter(function (category) {
      return category.services.length > 0;
    }),
    renderHeader: renderCategoryHeader,
    renderContent: renderCategoryContent,
    onChange: onActiveSectionChange,
    underlayColor: '#ffffff'
  })) : showEmptySearchResults ? __jsx(EmptySearchResults, null) : __jsx(EmptySearch, {
    title: Localize('services.emptySearchTitle'),
    body: emptySearchText(preferredStaff === null || preferredStaff === void 0 ? void 0 : preferredStaff.firstName)
  }));
}
var ServiceContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-top: 5px;\n  padding: 0px 20px 20px 20px;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: stretch;\n"])));