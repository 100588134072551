import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import React from "react";
var __jsx = React.createElement;
import { Image, View } from 'react-native';
import { localImageSource, LocalImageEnum } from '~/common/Helpers';
import Localize from '~/common/Localize';
import { themed, Container, Hero, Body3 } from '~/styles/Theme';
var EmptySearchResults = function EmptySearchResults() {
  return __jsx(View, {
    style: {
      flex: 1,
      marginTop: 48
    }
  }, __jsx(SearchTextContainer, {
    testID: "emptyStateImage"
  }, __jsx(EmptySearchImage, {
    source: localImageSource(LocalImageEnum.emptySearch)
  }), __jsx(SearchTextTitle, null, Localize('searchBar.emptySearch')), __jsx(SearchTextSubtitle, null, Localize('searchbar.tryAgain'))));
};
export default EmptySearchResults;
var EmptySearchImage = themed(Image)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 213px;\n  height: 108px;\n  margin-bottom: 16px;\n  align-self: center;\n  margin-top: 48px\n"])));
var SearchTextContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  bottom: 26px;\n  text-align: center;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 0px 20px 0px 20px;\n"])));
var SearchTextTitle = themed(Hero)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: #282F3C;\n  margin-bottom: 10px\n"])));
var SearchTextSubtitle = themed(Body3)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: #282F3C\n  text-align: center;\n"])));